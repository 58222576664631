// 001 single-xxxxxxx ++++++++++++++++++++++++++++++++++++++++++++++++++++++
section.single.topic{
	& .w{
		& .c{
		}
	}
	.meta{
		margin-bottom: 1em;
		time{
			@include font-size(16);
			@include font-maven;
			display: block;
			letter-spacing: .15em;
			color: lighten($gray, 20%);
		}
	}
	.eyecatch{
		margin-bottom: 2rem;
	}
	.content{
		p{
			@include font-size(16);
			font-weight: 400;
			line-height: 1.75;
			letter-spacing: .05em;
			color: lighten($gray, 10%);
		}
	}
}
