// 001 header ++++++++++++++++++++++++++++++++++++++++++++++++++++++
.heading-1{
	h1{
		span{
			display: block;
			color: $white;
			&.eng{
				@include font-maven;
				@include font-size(16);
				font-weight: 900;
				margin-bottom: 4px;
			}
			&.jpn{
				@include font-size(10);
				font-weight: 700;
				letter-spacing: .83px;
				color: $white;
			}
		}
	}
	&.single{
		@include font-size(36);
		position: relative;
		margin-bottom: .8em;
		padding-bottom: .8em;
		&:after{
			position: absolute;
			bottom: -3px;
			display: block;
			width: 120px;
			height: 6px;
			content: '';
			background-color: $red;
		}
		h1{
			@include font-size(28);
			font-weight: 300;
			letter-spacing: .83px;
			color: $gray;
		}
	}
}


.heading-2{
	margin-bottom: 100px;
	h2{
		font-size: 36px;
		font-weight: 700;
		margin-bottom: 2px;
		letter-spacing: -.5px;
		color: $gray;
	}
	span.jpn{
		font-size: 10px;
		font-weight: 700;
		margin-left: 100px;
		letter-spacing: -.5px;
		color: $light-gray;
	}
}

.heading-3{
	font-weight: 700;
	color: $white;
	time{
		@include font-maven;
		@include font-size(12);
		font-weight: 700;
		margin: 0 auto 1em;
	}
	h3{
		@include font-size(16);
		line-height: 1.6;
		text-align: center;
		letter-spacing: 1px;
	}
}
