// 001 default setting ++++++++++++++++++++++++++++++++++++++++++++++++++++++
html{
	font-size: 14px;
	min-width: 1200px;
}

body{
	@include font-smoothing(antialiased);
	@include placeholder(#bbb);
	@include font-noto;
	font-weight: 300;
	position: relative;
	min-width: 1200px;
	background-color: transparent;
	background-image: none;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	text-size-adjust: 100%;
	text-rendering: optimizeSpeed;
	-webkit-tap-highlight-color: transparent;
	-webkit-font-feature-settings: 'palt';
	-moz-font-feature-settings: 'palt';
	font-feature-settings: 'palt';
}

body{
	background-color: $high-light-gray;
}


.parent-wrap{
	@include flex;
	position: relative;
	align-items: stretch;
	justify-content: flex-start;
	width: 100%;
	height: 100%;
	header{
		width: 25vw;
		min-width: $default-header-width;
		max-width: $default-header-width;
		background-color: $red;
	}
	main{
		width: 100%;
		min-width: $default-content-width;
		margin-left: 200px;
		background-color: $high-light-gray;
		section{
			width: 100%;
			min-width: 1000px;
			& .w{
				width: 100%;
				padding: 100px 30px;
				& .c{
					width: 100%;
				}
			}
		}
	}
}


header{
	position: fixed;
	top: 0;
	left: 0;
	padding: 30px;
	& .w{
		width: 100%;
		height: 100vh;
		& .c{
			width: 100%;
			text-align: center;
		}
	}
	.logo-w{
		margin-bottom: 80px;
		.logo{
			margin-bottom: 12px;
		}
	}
}

footer{
	& .w{
		width: 100%;
		padding: 2rem 0;
		& .c{
			width: 100%;
			text-align: center;
		}
	}
	small{
		width: 100%;
		address{
			@include font-size(11);
			@include font-maven;
			width: 100%;
			text-align: center;
			letter-spacing: .1em;
			text-transform: uppercase;
			color: $gray;
		}
	}
}
