// 001 <em, storng, span.strong> font-weight bold ++++++++++++++++++++++++++++++++++++++++++++
em,
strong,
span.strong{
	font-weight: bold;
	font-style: normal;
}

span.caption{
	font-size: .8em;
	display: inline-block;
	&.jpn-brackets{
		margin-left: -.5em;
	}
}

// 002 <img> centering ++++++++++++++++++++++++++++++++++++++++++++
img.img-center{
	display: block;
	margin-right: auto;
	margin-left: auto;
}

.eng{
	@include font-maven;
}

.jpn{
	@include font-noto;
}

// 002 <img> centering ++++++++++++++++++++++++++++++++++++++++++++
div.media{
	// display: none;
	position: fixed;
	z-index: 99999;
	right: 4px;
	bottom: 4px;
	span{
		@include font-size(14);
		font-weight: 700;
		display: inline-block;
		display: none;
		padding: 2px 4px;
		color: #fff;
		border-radius: 2px;
		background-color: red;
		@media (min-width: 0) and (max-width: 1400px){
			&:not(.dl){
				display: none;
			}
			&.dl{
				display: inline-block;
				background-color: black;
			}
		}
		@media (min-width: 0) and (max-width: 1200px){
			&:not(.dm){
				display: none;
			}
			&.dm{
				display: inline-block;
				background-color: gray;
			}
		}
		@media (min-width: 0) and (max-width: 960px){
			&:not(.ds){
				display: none;
			}
			&.ds{
				display: inline-block;
				background-color: silver;
			}
		}
		@media (min-width: 0) and (max-width: 860px){
			&:not(.tl){
				display: none;
			}
			&.tl{
				display: inline-block;
				background-color: olive;
			}
		}
		@media (min-width: 0) and (max-width: 768px){
			&:not(.tm){
				display: none;
			}
			&.tm{
				display: inline-block;
				background-color: blue;
			}
		}
		@media (min-width: 0) and (max-width: 640px){
			&:not(.ts){
				display: none;
			}
			&.ts{
				display: inline-block;
				background-color: navy;
			}
		}
		@media (min-width: 0) and (max-width: 480px){
			&:not(.sl){
				display: none;
			}
			&.sl{
				display: inline-block;
				background-color: teal;
			}
		}
		@media (min-width: 0) and (max-width: 380px){
			&:not(.sm){
				display: none;
			}
			&.sm{
				display: inline-block;
				background-color: green;
			}
		}
		@media (min-width: 0) and (max-width: 360px){
			&:not(.ss){
				display: none;
			}
			&.ss{
				display: inline-block;
				background-color: lime;
			}
		}
		@media (min-width: 0) and (max-width: 320px){
			&:not(.sx){
				display: none;
			}
			&.sx{
				display: inline-block;
				background-color: aqua;
			}
		}
	}
}
