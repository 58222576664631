// 001 fontsize ++++++++++++++++++++++++++++++++++++++++++++++++++++++
@mixin font-size($size: 14, $base: 14){
	font-size: $size + px;
	font-size: ($size/$base) + rem;
}

// 002 webfont ++++++++++++++++++++++++++++++++++++++++++++++++++++++
@mixin font-noto{
	font-family: 'Noto Sans Japanese', sans-serif;
}

@mixin font-maven{
	font-family: 'Maven Pro', sans-serif;
}

// 003 flexible box ++++++++++++++++++++++++++++++++++++++++++++++++++++++
@mixin flex{
	display: flex;
	align-content: flex-start;
	align-items: flex-start;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	flex-align: center;
	& > *{
		min-height: percentage(0);
	}
}

// 005 antialiased ++++++++++++++++++++++++++++++++++++++++++++++++++++++
@mixin font-smoothing($value: antialiased){
	@if $value == antialiased{
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
	@else{
		-webkit-font-smoothing: subpixel-antialiased;
		-moz-osx-font-smoothing: auto;
	}
}

// 006 text overflow ++++++++++++++++++++++++++++++++++++++++++++++++++++++
@mixin text-overflow(){
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

// 007 placeholder ++++++++++++++++++++++++++++++++++++++++++++++++++++++
@mixin placeholder($color){
	input:-moz-placeholder,
	textarea:-moz-placeholder{
		color: $color;
	}
	input::-moz-placeholder,
	textarea::-moz-placeholder{
		color: $color;
	}
	input:-ms-input-placeholder,
	textarea:-ms-input-placeholder{
		color: $color;
	}
	input::-webkit-input-placeholder,
	textarea::-webkit-input-placeholder{
		color: $color;
	}
}

@mixin common-style-table{
	table{
		width: 100%;
		table-layout: fixed;
		border-collapse: collapse;
		tbody{
			tr{
				display: table;
				width: 100%;
				margin-bottom: 4px;
				padding-bottom: 4px;
				border-bottom: 3px solid rgba($light-gray, .3);
				&:last-child{
					border-bottom: none;
				}
				th,
				td{
					@include font-size(14);
					font-weight: 300;
					line-height: 2.06;
					display: table-cell;
					letter-spacing: .88px;
				}
				th{
					@include font-size(14);
					font-weight: 300;
					width: 30%;
					padding: .5em 1em .5em 2em;
					text-align: left;
					white-space: nowrap;
					letter-spacing: 2px;
					& > span{
						&.req{
							@include font-size(10);
							font-weight: 500;
							display: inline-block;
							margin-left: .8em;
							padding: .2em .5em;
							vertical-align: 10%;
							letter-spacing: 1px;
							color: $white;
							border: 1px solid $red;
							border-radius: 3px;
							background-color: $red;
						}
						&.small{
							@include font-size(12);
							display: inline-block;
							margin: 0 -.3em;
							letter-spacing: normal;
						}
					}
				}
				td{
					@include font-size(14);
					font-weight: 300;
					width: 70%;
					padding: .5em 1em .5em .5em;
					span.wpcf7-not-valid-tip{
						@include font-size(11);
						font-weight: 500;
						margin-top: .8em;
						color: $red;
					}
				}
			}
		}
	}
}
