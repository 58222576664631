/*--------------------------------------------------
このCSSはGulp+SCSSを使ってコンパイルしています。
CSSの内容を変更してもSCSSを変更しない限り差分が発生するため
かならずSCSSにも追記をお願いします。
--------------------------------------------------*/
@charset 'UTF-8';

// common ++++++++++++++++++++++++++++++++++++++++++++++++++++++
@import 'asset/reset';
@import 'asset/mediaquery';
@import 'asset/variables';
@import 'asset/grid';
@import 'asset/mixins';
@import 'asset/extend';
@import 'asset/fonts';
@import 'asset/form';
@import 'asset/utility';

// box model ++++++++++++++++++++++++++++++++++++++++++++++++++++++
// @import 'library/animate/animate';
// @import 'library/yarpp/core';

// box model ++++++++++++++++++++++++++++++++++++++++++++++++++++++
@import 'model/layout';
@import 'model/header';
@import 'model/footer';
@import 'model/navigation';
@import 'model/aside';
@import 'model/button';
@import 'model/heading';
@import 'model/catch';
@import 'model/hero';
@import 'model/table';
@import 'model/icon';

// page ++++++++++++++++++++++++++++++++++++++++++++++++++++++
@import 'page/index';
@import 'page/alliance';
@import 'page/company';
@import 'page/contact';
@import 'page/404';

// single ++++++++++++++++++++++++++++++++++++++++++++++++++++++
@import 'single/single';
