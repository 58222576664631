@charset "UTF-8";
/*--------------------------------------------------
このCSSはGulp+SCSSを使ってコンパイルしています。
CSSの内容を変更してもSCSSを変更しない限り差分が発生するため
かならずSCSSにも追記をお願いします。
--------------------------------------------------*/
@import url("https://fonts.googleapis.com/earlyaccess/notosansjapanese.css");
@import url("https://fonts.googleapis.com/css?family=Maven+Pro:400,500,700,900");
* {
  font-size: 100%;
  font-style: normal;
  line-height: 1;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

main,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ul,
ol,
li {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  table-layout: fixed;
  border-spacing: 0;
  border-collapse: collapse;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}

a:hover {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

textarea,
select,
button,
input[type='button'],
input[type='submit'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='email'],
input[type='month'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
input[type='week'] {
  font-size: 1em;
  box-sizing: border-box;
  min-height: 1em;
  margin: 0;
  padding: 0;
  text-align: left;
  vertical-align: middle;
  color: #000;
  border: 0 none #fff;
  border-radius: 0;
  outline: 0;
  background-color: #fff;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select {
  padding: 0 25px 0 8px;
  background: #fff url(data:image/png;base64,R0lGODlhDQAEAIAAAAAAAP8A/yH5BAEHAAEALAAAAAANAAQAAAILhA+hG5jMDpxvhgIAOw==);
  background-repeat: no-repeat;
  background-position: 97% center;
}

button {
  cursor: pointer;
  border: 0 none #fff;
  background-color: transparent;
}

input[type='submit']::-webkit-search-decoration,
input[type='button']::-webkit-search-decoration {
  display: none;
}

input[type='submit']::focus,
input[type='button']::focus {
  outline-offset: -2px;
}

input[type='submit']::focus,
input[type='button']::focus {
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
}

form .contact-before-transmission {
  display: block;
  transition: 300ms;
  opacity: 1;
}

form .contact-after-transmission {
  display: none;
  transition: 300ms;
  opacity: 0;
  /* 確認時は下記をアクティブに */
}

form.sent .contact-before-transmission {
  display: none;
  transition: 300ms;
  opacity: 0;
}

form.sent .contact-after-transmission {
  display: block;
  transition: 300ms;
  opacity: 1;
}

.wpcf7-response-output {
  display: none !important;
}

input[type='text'],
select,
textarea {
  font-size: 14px;
  font-size: 1rem;
  padding: .5em .8em;
  border: 1px solid #9c9c9c;
  border-radius: 2px;
  background-color: #fff;
}

input[type='text']:focus,
select:focus,
textarea:focus {
  border: 1px solid #333;
}

input[type='text'].md,
select.md,
textarea.md {
  width: 100%;
}

input[type='text'].xlg,
select.xlg,
textarea.xlg {
  width: 100%;
}

.button-form {
  margin-top: 2rem;
}

.button-form .button-submit,
.button-form .button-error {
  text-align: center;
}

button {
  font-size: 16px;
  font-size: 1.14286rem;
  font-weight: 700;
  min-width: 320px;
  padding: 1em 3em;
  text-align: center;
  letter-spacing: 2px;
  border-radius: 100px;
}

button.error {
  cursor: no-drop;
  color: #333;
  background-color: #9c9c9c;
}

button.submit {
  color: #fff;
  background-color: #c4131a;
}

textarea {
  line-height: 1.65;
}

.contact-after-transmission p.title {
  font-size: 24px;
  font-size: 1.71429rem;
  font-weight: 400;
  margin-bottom: 2rem;
  letter-spacing: 1px;
  color: #c4131a;
}

.contact-after-transmission p.lead {
  font-size: 14px;
  font-size: 1rem;
  line-height: 1.7;
  margin-bottom: 1.8rem;
  letter-spacing: .5px;
}

.contact-after-transmission ul {
  margin-bottom: 1.8rem;
}

.contact-after-transmission ul li {
  margin-bottom: .8em;
}

.contact-after-transmission ul li:last-child {
  margin-bottom: 0;
}

em,
strong,
span.strong {
  font-weight: bold;
  font-style: normal;
}

span.caption {
  font-size: .8em;
  display: inline-block;
}

span.caption.jpn-brackets {
  margin-left: -.5em;
}

img.img-center {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.eng {
  font-family: 'Maven Pro', sans-serif;
}

.jpn {
  font-family: 'Noto Sans Japanese', sans-serif;
}

div.media {
  position: fixed;
  z-index: 99999;
  right: 4px;
  bottom: 4px;
}

div.media span {
  font-size: 14px;
  font-size: 1rem;
  font-weight: 700;
  display: inline-block;
  display: none;
  padding: 2px 4px;
  color: #fff;
  border-radius: 2px;
  background-color: red;
}

@media (min-width: 0) and (max-width: 1400px) {
  div.media span:not(.dl) {
    display: none;
  }
  div.media span.dl {
    display: inline-block;
    background-color: black;
  }
}

@media (min-width: 0) and (max-width: 1200px) {
  div.media span:not(.dm) {
    display: none;
  }
  div.media span.dm {
    display: inline-block;
    background-color: gray;
  }
}

@media (min-width: 0) and (max-width: 960px) {
  div.media span:not(.ds) {
    display: none;
  }
  div.media span.ds {
    display: inline-block;
    background-color: silver;
  }
}

@media (min-width: 0) and (max-width: 860px) {
  div.media span:not(.tl) {
    display: none;
  }
  div.media span.tl {
    display: inline-block;
    background-color: olive;
  }
}

@media (min-width: 0) and (max-width: 768px) {
  div.media span:not(.tm) {
    display: none;
  }
  div.media span.tm {
    display: inline-block;
    background-color: blue;
  }
}

@media (min-width: 0) and (max-width: 640px) {
  div.media span:not(.ts) {
    display: none;
  }
  div.media span.ts {
    display: inline-block;
    background-color: navy;
  }
}

@media (min-width: 0) and (max-width: 480px) {
  div.media span:not(.sl) {
    display: none;
  }
  div.media span.sl {
    display: inline-block;
    background-color: teal;
  }
}

@media (min-width: 0) and (max-width: 380px) {
  div.media span:not(.sm) {
    display: none;
  }
  div.media span.sm {
    display: inline-block;
    background-color: green;
  }
}

@media (min-width: 0) and (max-width: 360px) {
  div.media span:not(.ss) {
    display: none;
  }
  div.media span.ss {
    display: inline-block;
    background-color: lime;
  }
}

@media (min-width: 0) and (max-width: 320px) {
  div.media span:not(.sx) {
    display: none;
  }
  div.media span.sx {
    display: inline-block;
    background-color: aqua;
  }
}

html {
  font-size: 14px;
  min-width: 1200px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Noto Sans Japanese', sans-serif;
  font-weight: 300;
  position: relative;
  min-width: 1200px;
  background-color: transparent;
  background-image: none;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  text-size-adjust: 100%;
  text-rendering: optimizeSpeed;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-feature-settings: 'palt';
  -moz-font-feature-settings: 'palt';
  font-feature-settings: 'palt';
}

body input:-moz-placeholder,
body textarea:-moz-placeholder {
  color: #bbb;
}

body input::-moz-placeholder,
body textarea::-moz-placeholder {
  color: #bbb;
}

body input:-ms-input-placeholder,
body textarea:-ms-input-placeholder {
  color: #bbb;
}

body input::-webkit-input-placeholder,
body textarea::-webkit-input-placeholder {
  color: #bbb;
}

body {
  background-color: #fafafa;
}

.parent-wrap {
  display: flex;
  align-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  flex-align: center;
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
}

.parent-wrap > * {
  min-height: 0%;
}

.parent-wrap header {
  width: 25vw;
  min-width: 200px;
  max-width: 200px;
  background-color: #c4131a;
}

.parent-wrap main {
  width: 100%;
  min-width: 1000px;
  margin-left: 200px;
  background-color: #fafafa;
}

.parent-wrap main section {
  width: 100%;
  min-width: 1000px;
}

.parent-wrap main section .w {
  width: 100%;
  padding: 100px 30px;
}

.parent-wrap main section .w .c {
  width: 100%;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  padding: 30px;
}

header .w {
  width: 100%;
  height: 100vh;
}

header .w .c {
  width: 100%;
  text-align: center;
}

header .logo-w {
  margin-bottom: 80px;
}

header .logo-w .logo {
  margin-bottom: 12px;
}

footer .w {
  width: 100%;
  padding: 2rem 0;
}

footer .w .c {
  width: 100%;
  text-align: center;
}

footer small {
  width: 100%;
}

footer small address {
  font-size: 11px;
  font-size: 0.78571rem;
  font-family: 'Maven Pro', sans-serif;
  width: 100%;
  text-align: center;
  letter-spacing: .1em;
  text-transform: uppercase;
  color: #333;
}

.heading-1 h1 span {
  display: block;
  color: #fff;
}

.heading-1 h1 span.eng {
  font-family: 'Maven Pro', sans-serif;
  font-size: 16px;
  font-size: 1.14286rem;
  font-weight: 900;
  margin-bottom: 4px;
}

.heading-1 h1 span.jpn {
  font-size: 10px;
  font-size: 0.71429rem;
  font-weight: 700;
  letter-spacing: .83px;
  color: #fff;
}

.heading-1.single {
  font-size: 36px;
  font-size: 2.57143rem;
  position: relative;
  margin-bottom: .8em;
  padding-bottom: .8em;
}

.heading-1.single:after {
  position: absolute;
  bottom: -3px;
  display: block;
  width: 120px;
  height: 6px;
  content: '';
  background-color: #c4131a;
}

.heading-1.single h1 {
  font-size: 28px;
  font-size: 2rem;
  font-weight: 300;
  letter-spacing: .83px;
  color: #333;
}

.heading-2 {
  margin-bottom: 100px;
}

.heading-2 h2 {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 2px;
  letter-spacing: -.5px;
  color: #333;
}

.heading-2 span.jpn {
  font-size: 10px;
  font-weight: 700;
  margin-left: 100px;
  letter-spacing: -.5px;
  color: #9c9c9c;
}

.heading-3 {
  font-weight: 700;
  color: #fff;
}

.heading-3 time {
  font-family: 'Maven Pro', sans-serif;
  font-size: 12px;
  font-size: 0.85714rem;
  font-weight: 700;
  margin: 0 auto 1em;
}

.heading-3 h3 {
  font-size: 16px;
  font-size: 1.14286rem;
  line-height: 1.6;
  text-align: center;
  letter-spacing: 1px;
}

nav.g > ul > li {
  display: table;
  margin-bottom: 24px;
}

nav.g > ul > li:last-child {
  margin-bottom: 0;
}

nav.g > ul > li > a {
  font-family: 'Maven Pro', sans-serif;
  font-size: 16px;
  font-size: 1.14286rem;
  font-weight: 900;
  position: relative;
  display: inline-block;
  width: 8em;
  padding: .3em 0;
  text-align: center;
  color: #fff;
}

nav.g > ul > li > a:before, nav.g > ul > li > a:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
}

nav.g > ul > li > a:before {
  transition: 500ms;
  transform: scale(0);
  transform-origin: top left;
  border-top: 1px solid #fff;
  border-left: 1px solid #fff;
}

nav.g > ul > li > a:after {
  transition: 500ms;
  transform: scale(0);
  transform-origin: bottom right;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
}

nav.g > ul > li > a:hover:before {
  transition: 500ms;
  transform: scale(1);
  transform-origin: top left;
  border-top: 1px solid #fff;
  border-left: 1px solid #fff;
}

nav.g > ul > li > a:hover:after {
  transition: 500ms;
  transform: scale(1);
  transform-origin: bottom right;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
}

section.hero {
  padding: 300px 0 360px;
  background: transparent url("../images/bg-hero.png") center center no-repeat;
  background-size: cover;
}

section.hero .hero-c p {
  font-weight: 700;
  display: table;
  letter-spacing: -.5px;
  text-transform: uppercase;
  color: #fff;
}

section.hero .hero-c p.main-title {
  font-family: 'Maven Pro', sans-serif;
  font-size: 36px;
  font-size: 2.57143rem;
  margin-bottom: 8px;
  padding: .3em 16px;
  background-color: #c4131a;
}

section.hero .hero-c p.sub-title {
  font-size: 20px;
  font-size: 1.42857rem;
  font-family: 'Maven Pro', sans-serif;
  padding: .5em 16px;
  background-color: #333;
}

section.topic .topic-c {
  display: flex;
  align-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  flex-align: center;
  width: 100%;
}

section.topic .topic-c > * {
  min-height: 0%;
}

section.topic .topic-c .topic-i {
  position: relative;
  display: block;
  overflow: hidden;
  width: calc( ( 100% - 6px));
  height: 20vw;
  margin-right: 2px;
  cursor: pointer;
  background-color: #c4131a;
}

section.topic .topic-c .topic-i .overlay {
  position: absolute;
  z-index: 20;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  transition: 250ms;
  background-color: rgba(26, 26, 26, 0.8);
}

section.topic .topic-c .topic-i img {
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  transition: 250ms;
  transform: scale(1);
  object-fit: cover;
}

section.topic .topic-c .topic-i .heading-g {
  display: flex;
  align-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  flex-align: center;
  position: absolute;
  z-index: 50;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

section.topic .topic-c .topic-i .heading-g > * {
  min-height: 0%;
}

section.topic .topic-c .topic-i .heading-g .heading-3 {
  display: flex;
  align-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  flex-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 0 30px;
}

section.topic .topic-c .topic-i .heading-g .heading-3 > * {
  min-height: 0%;
}

section.topic .topic-c .topic-i:hover .overlay {
  transition: 250ms;
  background-color: rgba(150, 14, 20, 0.8);
}

section.topic .topic-c .topic-i:hover img {
  transition: 250ms;
  transform: scale(1.1);
}

section.about {
  background: #fff url("../images/bg-about.png") right -200px center no-repeat;
  background-size: contain;
}

section.about .about-c .about-i p {
  font-size: 14px;
  font-size: 1rem;
  font-weight: 300;
  line-height: 2.06;
  margin-bottom: 2em;
  letter-spacing: .88px;
  color: #333;
}

section.about .about-c .about-table {
  width: 40%;
}

section.about .about-c .about-table table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

section.about .about-c .about-table table tbody tr {
  display: table;
  width: 100%;
  margin-bottom: 4px;
  padding-bottom: 4px;
  border-bottom: 3px solid rgba(156, 156, 156, 0.3);
}

section.about .about-c .about-table table tbody tr:last-child {
  border-bottom: none;
}

section.about .about-c .about-table table tbody tr th,
section.about .about-c .about-table table tbody tr td {
  font-size: 14px;
  font-size: 1rem;
  font-weight: 300;
  line-height: 2.06;
  display: table-cell;
  letter-spacing: .88px;
}

section.about .about-c .about-table table tbody tr th {
  font-size: 14px;
  font-size: 1rem;
  font-weight: 300;
  width: 30%;
  padding: .5em 1em .5em 2em;
  text-align: left;
  white-space: nowrap;
  letter-spacing: 2px;
}

section.about .about-c .about-table table tbody tr th > span.req {
  font-size: 10px;
  font-size: 0.71429rem;
  font-weight: 500;
  display: inline-block;
  margin-left: .8em;
  padding: .2em .5em;
  vertical-align: 10%;
  letter-spacing: 1px;
  color: #fff;
  border: 1px solid #c4131a;
  border-radius: 3px;
  background-color: #c4131a;
}

section.about .about-c .about-table table tbody tr th > span.small {
  font-size: 12px;
  font-size: 0.85714rem;
  display: inline-block;
  margin: 0 -.3em;
  letter-spacing: normal;
}

section.about .about-c .about-table table tbody tr td {
  font-size: 14px;
  font-size: 1rem;
  font-weight: 300;
  width: 70%;
  padding: .5em 1em .5em .5em;
}

section.about .about-c .about-table table tbody tr td span.wpcf7-not-valid-tip {
  font-size: 11px;
  font-size: 0.78571rem;
  font-weight: 500;
  margin-top: .8em;
  color: #c4131a;
}

section.business {
  background: #fff url("../images/bg-business.png") center center no-repeat;
  background-size: cover;
}

section.business .business-c {
  display: flex;
  align-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  flex-align: center;
  flex-wrap: wrap;
  width: 100%;
}

section.business .business-c > * {
  min-height: 0%;
}

section.business .business-c .business-i {
  display: flex;
  align-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  flex-align: center;
  position: relative;
  align-items: center;
  justify-content: center;
  width: calc( ( 100% - 120px ) / 3);
  height: 200px;
  padding: 0 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(51, 51, 51, 0.1);
}

section.business .business-c .business-i > * {
  min-height: 0%;
}

section.business .business-c .business-i:nth-child(5), section.business .business-c .business-i:nth-child(2) {
  margin-top: 60px;
}

section.business .business-c .business-i .num-w {
  display: flex;
  align-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  flex-align: center;
  position: absolute;
  top: -10px;
  left: -10px;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #c4131a;
}

section.business .business-c .business-i .num-w > * {
  min-height: 0%;
}

section.business .business-c .business-i .num-w span {
  font-family: 'Maven Pro', sans-serif;
  font-size: 20px;
  font-size: 1.42857rem;
  font-weight: 500;
  color: #fff;
}

section.business .business-c .business-i .ic {
  text-align: center;
}

section.business .business-c .business-i .ic .icon {
  width: 60px;
  margin: 0 auto;
}

section.business .business-c .business-i .ic .label {
  font-family: 'Noto Sans Japanese', sans-serif;
  font-size: 14px;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.65;
  color: #333;
}

section.inquiry .inquiry-c {
  display: flex;
  align-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  flex-align: center;
  width: 100%;
}

section.inquiry .inquiry-c > * {
  min-height: 0%;
}

section.inquiry .inquiry-c .inquiry-i {
  width: 48%;
}

section.inquiry .inquiry-c .inquiry-i table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

section.inquiry .inquiry-c .inquiry-i table tbody tr {
  display: table;
  width: 100%;
  margin-bottom: 4px;
  padding-bottom: 4px;
  border-bottom: 3px solid rgba(156, 156, 156, 0.3);
}

section.inquiry .inquiry-c .inquiry-i table tbody tr:last-child {
  border-bottom: none;
}

section.inquiry .inquiry-c .inquiry-i table tbody tr th,
section.inquiry .inquiry-c .inquiry-i table tbody tr td {
  font-size: 14px;
  font-size: 1rem;
  font-weight: 300;
  line-height: 2.06;
  display: table-cell;
  letter-spacing: .88px;
}

section.inquiry .inquiry-c .inquiry-i table tbody tr th {
  font-size: 14px;
  font-size: 1rem;
  font-weight: 300;
  width: 30%;
  padding: .5em 1em .5em 2em;
  text-align: left;
  white-space: nowrap;
  letter-spacing: 2px;
}

section.inquiry .inquiry-c .inquiry-i table tbody tr th > span.req {
  font-size: 10px;
  font-size: 0.71429rem;
  font-weight: 500;
  display: inline-block;
  margin-left: .8em;
  padding: .2em .5em;
  vertical-align: 10%;
  letter-spacing: 1px;
  color: #fff;
  border: 1px solid #c4131a;
  border-radius: 3px;
  background-color: #c4131a;
}

section.inquiry .inquiry-c .inquiry-i table tbody tr th > span.small {
  font-size: 12px;
  font-size: 0.85714rem;
  display: inline-block;
  margin: 0 -.3em;
  letter-spacing: normal;
}

section.inquiry .inquiry-c .inquiry-i table tbody tr td {
  font-size: 14px;
  font-size: 1rem;
  font-weight: 300;
  width: 70%;
  padding: .5em 1em .5em .5em;
}

section.inquiry .inquiry-c .inquiry-i table tbody tr td span.wpcf7-not-valid-tip {
  font-size: 11px;
  font-size: 0.78571rem;
  font-weight: 500;
  margin-top: .8em;
  color: #c4131a;
}

section.inquiry .inquiry-c .inquiry-i table tbody tr th {
  width: 50%;
  padding: 0.5em 1em calc(.5em + 12px) 1em;
  letter-spacing: 1px;
}

section.inquiry .inquiry-c .inquiry-i table tbody tr td {
  width: 50%;
}

section.single.topic .meta {
  margin-bottom: 1em;
}

section.single.topic .meta time {
  font-size: 16px;
  font-size: 1.14286rem;
  font-family: 'Maven Pro', sans-serif;
  display: block;
  letter-spacing: .15em;
  color: #666666;
}

section.single.topic .eyecatch {
  margin-bottom: 2rem;
}

section.single.topic .content p {
  font-size: 16px;
  font-size: 1.14286rem;
  font-weight: 400;
  line-height: 1.75;
  letter-spacing: .05em;
  color: #4d4d4d;
}
