// 001 form reset ++++++++++++++++++++++++++++++++++++++++++++++++++++++
textarea,
select,
button,
input[type='button'],
input[type='submit'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='email'],
input[type='month'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
input[type='week']{
	font-size: 1em;
	box-sizing: border-box;
	min-height: 1em;
	margin: 0;
	padding: 0;
	text-align: left;
	vertical-align: middle;
	color: #000;
	border: 0 none #fff;
	border-radius: 0;
	outline: 0;
	background-color: #fff;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

select{
	padding: 0 25px 0 8px;
	background: #fff url(data:image/png;base64,R0lGODlhDQAEAIAAAAAAAP8A/yH5BAEHAAEALAAAAAANAAQAAAILhA+hG5jMDpxvhgIAOw==);
	background-repeat: no-repeat;
	background-position: 97% center;
}

button{
	cursor: pointer;
	border: 0 none #fff;
	background-color: transparent;
}

input[type='submit']::-webkit-search-decoration,
input[type='button']::-webkit-search-decoration{
	display: none;
}

input[type='submit']::focus,
input[type='button']::focus{
	outline-offset: -2px;
}

input[type='submit']::focus,
input[type='button']::focus{
	-webkit-appearance: button;
	-moz-appearance: button;
	appearance: button;
}

form{
	.contact-before-transmission{
		display: block;
		transition: 300ms;
		opacity: 1;
	}
	.contact-after-transmission{
		display: none;
		transition: 300ms;
		opacity: 0;
		/* 確認時は下記をアクティブに */
		// display: block;
		// transition: 300ms;
		// opacity: 1;
	}
	&.sent{
		.contact-before-transmission{
			display: none;
			transition: 300ms;
			opacity: 0;
		}
		.contact-after-transmission{
			display: block;
			transition: 300ms;
			opacity: 1;
		}
	}
}

.wpcf7-response-output{
	display: none !important;
}



input[type='text'],
select,
textarea{
	@include font-size(14);
	padding: .5em .8em;
	border: 1px solid $light-gray;
	border-radius: 2px;
	background-color: $white;
	&:focus{
		border: 1px solid $gray;
	}
	&.md{
		width: 100%;
	}
	&.xlg{
		width: 100%;
	}
}

.button-form{
	margin-top: 2rem;
	.button-submit,
	.button-error{
		text-align: center;
	}
}

button{
	@include font-size(16);
	font-weight: 700;
	min-width: 320px;
	padding: 1em 3em;
	text-align: center;
	letter-spacing: 2px;
	border-radius: 100px;
	&.error{
		cursor: no-drop;
		color: $gray;
		background-color: $light-gray;
	}
	&.submit{
		color: $white;
		background-color: $red;
	}
}

// .button-error{
// 	display: block !important;
// }

textarea{
	line-height: 1.65;
}

.contact-after-transmission{
	p.title{
		@include font-size(24);
		font-weight: 400;
		margin-bottom: 2rem;
		letter-spacing: 1px;
		color: $red;
	}
	p.lead{
		@include font-size(14);
		line-height: 1.7;
		margin-bottom: 1.8rem;
		letter-spacing: .5px;
	}
	ul{
		margin-bottom: 1.8rem;
		li{
			margin-bottom: .8em;
			&:last-child{
				margin-bottom: 0;
			}
		}
	}
}

