section.hero{
	padding: 300px 0 360px;
	background: transparent url('../images/bg-hero.png') center center no-repeat;
	background-size: cover;
	& .w{
		& .c{
		}
	}
	.hero-c{
		p{
			font-weight: 700;
			display: table;
			letter-spacing: -.5px;
			text-transform: uppercase;
			color: $white;
			&.main-title{
				@include font-maven;
				@include font-size(36);
				margin-bottom: 8px;
				padding: .3em 16px;
				background-color: $red;
			}
			&.sub-title{
				@include font-size(20);
				@include font-maven;
				padding: .5em 16px;
				background-color: $gray;
			}
		}
	}
}

section.topic{
	& .w{
		& .c{
		}
	}
	.topic-c{
		@include flex;
		width: 100%;
		.topic-i{
			position: relative;
			display: block;
			overflow: hidden;
			width: calc( ( 100% - 6px) );
			height: 20vw;
			margin-right: 2px;
			cursor: pointer;
			background-color: $red;
			.overlay{
				position: absolute;
				z-index: 20;
				top: 0;
				left: 0;
				display: block;
				width: 100%;
				height: 100%;
				transition: 250ms;
				background-color: rgba(darken($gray, 10), .8);
			}
			img{
				position: absolute;
				z-index: 5;
				top: 0;
				left: 0;
				width: 100%;
				transition: 250ms;
				transform: scale(1);
				object-fit: cover;
			}
			.heading-g{
				@include flex;
				position: absolute;
				z-index: 50;
				align-items: center;
				justify-content: center;
				width: 100%;
				height: 100%;
				.heading-3{
					@include flex;
					align-items: center;
					flex-direction: column;
					justify-content: center;
					padding: 0 30px;
				}
			}
			&:hover{
				.overlay{
					transition: 250ms;
					background-color: rgba(darken($red, 10), .8);
				}
				img{
					transition: 250ms;
					transform: scale(1.1);
				}
			}
		}
	}
}

section.about{
	background: $white url('../images/bg-about.png') right -200px center no-repeat;
	background-size: contain;
	& .w{
		& .c{
		}
	}
	.about-c{
		.about-i{
			p{
				@include font-size(14);
				font-weight: 300;
				line-height: 2.06;
				margin-bottom: 2em;
				letter-spacing: .88px;
				color: $gray;
			}
		}
		.about-table{
			@include common-style-table;
			width: 40%;
		}
	}
}

section.business{
	background: $white url('../images/bg-business.png') center center no-repeat;
	background-size: cover;
	& .w{
		& .c{
		}
	}
	.business-c{
		@include flex;
		flex-wrap: wrap;
		width: 100%;
		.business-i{
			@include flex;
			position: relative;
			align-items: center;
			justify-content: center;
			width: calc( ( 100% - 120px ) / 3 );
			height: 200px;
			padding: 0 20px;
			background-color: $white;
			box-shadow: 0 0 10px rgba($gray, .1);
			&:nth-child(5),
			&:nth-child(2){
				margin-top: 60px;
			}
			.num-w{
				@include flex;
				position: absolute;
				top: -10px;
				left: -10px;
				align-items: center;
				justify-content: center;
				width: 40px;
				height: 40px;
				background-color: $red;
				span{
					@include font-maven;
					@include font-size(20);
					font-weight: 500;
					color: $white;
				}
			}
			.ic{
				text-align: center;
				.icon{
					width: 60px;
					margin: 0 auto;
				}
				.label{
					@include font-noto;
					@include font-size(14);
					font-weight: 500;
					line-height: 1.65;
					color: $gray;
				}
			}
		}
	}
}

section.inquiry{
	& .w{
		& .c{
		}
	}
	.inquiry-c{
		@include flex;
		width: 100%;
		.inquiry-i{
			@include common-style-table;
			width: 48%;
			table{
				tbody{
					tr{
						th{
							width: 50%;
							padding: .5em 1em calc(.5em + 12px) 1em;
							letter-spacing: 1px;
						}
						td{
							width: 50%;
						}
					}
				}
			}
		}
	}
}
