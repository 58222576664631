// reset ++++++++++++++++++++++++++++++++++++++++++++
*{
	font-size: 100%;
	font-style: normal;
	line-height: 1;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
}

h1,
h2,
h3,
h4,
h5,
h6{
	font-weight: normal;
}

main,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section{
	display: block;
}

ul,
ol,
li{
	list-style: none;
}

blockquote,
q{
	quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after{
	content: '';
	content: none;
}

table{
	table-layout: fixed;
	border-spacing: 0;
	border-collapse: collapse;
}

a{
	cursor: pointer;
	text-decoration: none;
	color: inherit;
	&:hover{
		text-decoration: none;
	}
}

img{
	max-width: 100%;
	height: auto;
}
