// 001 default setting ++++++++++++++++++++++++++++++++++++++++++++++++++++++
nav.g{
	& > ul{
		& > li{
			display: table;
			margin-bottom: 24px;
			&:last-child{
				margin-bottom: 0;
			}
			& > a{
				@include font-maven;
				@include font-size(16);
				font-weight: 900;
				position: relative;
				display: inline-block;
				width: 8em;
				padding: .3em 0;
				text-align: center;
				color: $white;
				&:before,
				&:after{
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					content: '';
				}
				&:before{
					transition: 500ms;
					transform: scale(0);
					transform-origin: top left;
					border-top: 1px solid $white;
					border-left: 1px solid $white;
				}
				&:after{
					transition: 500ms;
					transform: scale(0);
					transform-origin: bottom right;
					border-right: 1px solid $white;
					border-bottom: 1px solid $white;
				}
				&:hover{
					&:before{
						transition: 500ms;
						transform: scale(1);
						transform-origin: top left;
						border-top: 1px solid $white;
						border-left: 1px solid $white;
					}
					&:after{
						transition: 500ms;
						transform: scale(1);
						transform-origin: bottom right;
						border-right: 1px solid $white;
						border-bottom: 1px solid $white;
					}
				}
			}
		}
	}
}
